import React from 'react';
import img from '../Images/ucla-library.jpg';
import Hero from '../components/Hero';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';

const Contact = () => {
  return (
    <>
      <Navbar />
      <Hero 
      cName='hero-mid'
      img={img}
      title='Contact'/>
      <ContactForm />
      <Footer />
    </>
  );
}

export default Contact;
