import { Link } from 'react-router-dom';
import './Footer.css';
import React from 'react';

const Footer = () => {

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <div className='footer'>
      <div className='top'>
        <div>
            <h1>HigherEd Consulting</h1>
            <p>Empowering Academic Success At Every Level</p>
        </div>
        <div>
            <Link to='https://www.facebook.com/'>
                <i className='fa-brands fa-facebook-square' />
            </Link>
            <Link to='https://www.instagram.com/higher.ed.consulting/'>
                <i className='fa-brands fa-instagram-square' />
            </Link>
            <Link to='https://www.twitter.com/'>
                <i className='fa-brands fa-twitter-square' />
            </Link>
        </div>
      </div>
      <div className='bottom'>
          <Link to='/' onClick={scrollToTop}>Home</Link>
          <Link to='/About' onClick={scrollToTop}>About</Link>
          <Link to='/Staff' onClick={scrollToTop}>Staff</Link>
          <Link to='/Reviews' onClick={scrollToTop}>Reviews</Link>
          <Link to='/Contact' onClick={scrollToTop}>Contact</Link>
      </div>
    </div>
  );
}

export default Footer;
