import React from 'react';
import img from '../Images/ucla-library.jpg';
import Hero from '../components/Hero';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import AboutInfo from '../components/AboutInfo';

const About = () => {
  return (
    <>
      <Navbar />
      <Hero 
      cName='hero-mid'
      img={img}
      title='About'
      text='Learn more about who we are!'/>
      <AboutInfo />
      <Footer />
    </>
  );
}

export default About;
