import React from 'react';
import './ComingSoon.css';

const ComingSoon = () => {
  return (
    <div className='coming-soon'>
      <h1>Coming soon...</h1>
    </div>
  );
}

export default ComingSoon;
