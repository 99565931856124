import { Component } from 'react';
import './StaffSection.css';

class StaffData extends Component {
    render() {
        return (
            <div className='member'>
                <img src={this.props.img} alt='img' />
                <div className='member-text'>
                    <h2>{this.props.heading}</h2>
                    <p>
                        <b>School:</b>
                        &nbsp; {this.props.school}
                    </p>
                    <p>
                        <b>Degree:</b>
                        &nbsp; {this.props.degree}
                    </p>
                    <p>
                        <b>Current field of work/study:</b>
                        &nbsp; {this.props.work}
                    </p>
                </div>
            </div>
        )
    }
}

export default StaffData;