import './ContactForm.css';
import React from 'react';

const ContactForm = () => {
  return (
    <div className='form-container'>
      <h1>Send a message to us!</h1>
      <form>
        <input placeholder='Name' type='text' required/>
        <input placeholder='Email' type='email' required/>
        <input placeholder='Subject' type='text' required/>
        <textarea placeholder='Message' rows='4' type='text' required/>
        <button>Send Message</button>
      </form>
    </div>
  );
}

export default ContactForm;
