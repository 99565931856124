import React from 'react';
import Hero from '../components/Hero';
import img from '../Images/ucla-library.jpg';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Service from '../components/Service';

const Home = () => {
  return (
    <>
      <Navbar />
      <Hero 
      cName='hero'
      img={img}
      title='HigherEd Consulting'
      text='Empowering Academic Success At Every Level'/>
      <Service/>
      <Footer />
    </>
  );
}

export default Home;
