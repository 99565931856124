import './AboutInfo.css';
import React from 'react';
import img from '../Images/college-acceptance-letter.jpg';

const AboutInfo = () => {
  return (
    <div className='about-container'>
      <div>
          <h1>How We Started</h1>
          <p>At Higher-Ed Consulting, we believe in breaking down barriers to higher education. 
            Our journey began at the University of California, Los Angeles, where both Khajack Darbinian and Nicholas Degoian 
            found themselves assisting friends and family from their hometowns in navigating the complex path to college admission. 
            Recognizing the widespread need for accessible counseling in underserved communities, we joined forces, 
            pooling our resources and enlisting the help of fellow students from prestigious universities.</p>
          <h1>Our Mission</h1>
          <p>Driven by the conviction that knowledge should be freely shared, we founded Higher-Ed Consulting as a beacon of guidance 
            and support for students everywhere. Our mission is simple yet profound: to empower the next generation by providing comprehensive 
            assistance, entirely free of charge. By leveraging our own experiences and expertise, we aim to democratize access to higher 
            education, ensuring that no aspiring student is left behind due to lack of information or resources.</p>
          <h1>Our Future</h1>
          <p>At Higher-Ed Consulting, we are committed to fostering a culture of inclusivity and opportunity. Join us in our quest to unlock 
            the doors of possibility for students from all walks of life.</p>
      </div>
      <img src={img} alt=''/>
    </div>
  );
}

export default AboutInfo;
