import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Staff from './pages/Staff';
import Contact from './pages/Contact';
import Reviews from './pages/Review';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/About' element={<About />}/>
        <Route path='/Staff' element={<Staff />}/>
        <Route path='/Reviews' element={<Reviews />}/>
        <Route path='/Contact' element={<Contact />}/>
      </Routes>
    </div>
  );
}

export default App;
