import './Navbar.css';
import { React, useState } from 'react';
import { MenuItems } from './MenuItems';
import { Link } from 'react-router-dom';
import logo from '../Images/logo.jpg';

const Navbar = () => {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    const scrollToTop = () => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        setClick(false);
      }

    return (
        <nav className='NavbarItems'>
            <Link className='navbar-logo' to='/' onClick={scrollToTop}>
                <h1>HigherEd</h1>
                <img src={logo} alt='logo'/>
            </Link>
            <div className={'menu-icons'} onClick={handleClick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars'}></i>
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                {MenuItems.map((item, index) => {
                    return (
                        <li key={index}>
                            <Link className={item.cName} to={item.url} onClick={scrollToTop}>
                                <i className={item.icon}></i>
                                {item.title} 
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
}

export default Navbar;
