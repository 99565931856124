import './Service.css';
import React from 'react';
import ServiceData from './ServiceData';
import img from '../Images/ucla-library.jpg';

const Service = () => {
  return (
    <div className='service'>
      <h1>Services</h1>
      <p>At Higher-Ed Consulting, we offer consulting assistance to all levels of education progression. 
        We will help design a pathway to admissions processes including college admissions and many other post-graduate level positions. 
        Send us an email to let us know more about you and we will contact you for further info to help build your own specified pathway to 
        your dream university! Click contact below to get started.</p>
      <div className='service-card'>
        <ServiceData
        image={img}
        heading='HigherEd Consulting'
        text='Founded by graduates of the University of California, Los Angeles, we offer expert advice to students navigating 
        the admissions processes of prestigious universites across the nation.'
        />
      </div>
    </div>
  );
}

export default Service;
