import { Link } from 'react-router-dom';
import './Service.css';
import React from 'react';

const ServiceData = (props) => {

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <div className='s-card'>
      <div className='s-image'>
        <img src={props.image} alt='img'/>
      </div>
      <div className='service-content'>
        <h4>{props.heading}</h4>
        <p>{props.text}</p>
        <p className='online'>Now offering our services online! Message us to get started.</p>
        <Link to='/Contact' onClick={scrollToTop}>
          <button>Contact</button>
        </Link>
      </div>
    </div>
  );
}

export default ServiceData;
