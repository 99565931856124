import React from 'react';
import img from '../Images/ucla-library.jpg';
import Hero from '../components/Hero';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
// import ReviewsSection from '../components/ReviewsSection';
import ComingSoon from '../components/ComingSoon';

const Reviews = () => {
  return (
    <>
      <Navbar />
      <Hero 
      cName='hero-mid'
      img={img}
      title='Reviews'
      text='Read what people have said about us!'/>
      {/* <ReviewsSection /> */}
      <ComingSoon />
      <Footer />
    </>
  );
}

export default Reviews;