import './Hero.css';
import React from 'react';

const Hero = (props) => {
  return (
    <>
        <div className='hero'>
        <img src={props.img} alt='Img' />
        </div>
        <div className='hero-text'>
            <h1>{props.title}</h1>
            <p>{props.text}</p>
        </div>
    </>
  );
}

export default Hero;
