export const MenuItems = [
    {
        title : 'Home',
        url : '/',
        cName : 'nav-links',
        icon : 'fa-solid fa-house-user'
    },
    {
        title : 'About',
        url : '/About',
        cName : 'nav-links',
        icon : 'fa-solid fa-circle-info'
    },
    {
        title : 'Staff',
        url : '/Staff',
        cName : 'nav-links',
        icon : 'fa-solid fa-people-group'
    },
    {
        title : 'Reviews',
        url : '/Reviews',
        cName : 'nav-links',
        icon : 'fa-solid fa-comments'
    },
    {
        title : 'Contact',
        url : '/Contact',
        cName : 'nav-links',
        icon : 'fa-solid fa-envelope'
    }
]