import './StaffSection.css';
import React from 'react';
import StaffData from './StaffData';
import nichD from '../Images/NicholasD.jpg';
import khajD from '../Images/khajD.jpg';
import anthonyD from '../Images/AnthonyD.jpg';
import rafiH from '../Images/RafiH.png';
import lucianoB from '../Images/LucianoB.jpg';
import jonS from '../Images/jonS.jpg';
import gevA from '../Images/gevA.jpg';
import haikD from '../Images/haikD.jpg';
import shushannaD from '../Images/ShushannaD.jpg';

const StaffSection = () => {
  return (
    <div className='staff'>
        <h1>Co-founders</h1>
        <div className='leading-staff-group'>
            <StaffData
            heading='Nicholas Degoian'
            img={nichD}
            work='Orthopedic Surgery Research Assistant at UCLA / Opthalmic Surgery Technician'
            school='University of California, Los Angeles'
            degree='B.A in Philosophy'
            />
            <StaffData
            heading='Khajack Darbinian'
            work='Surgical Research, Medical Coding, and Surgery Simulation Development'
            school='University of California, Los Angeles'
            degree='B.A in Sociology'
            img={khajD}
            />
        </div>
        <h1>Consulting Team</h1>
        <div className='staff-group'>
            <StaffData
            heading='Rafi Hartounian'
            work='Software Engineer at Sierra-Cedar'
            school='University of California, Los Angeles'
            degree='B.S in Computer Science'
            img={rafiH}
            />
            <StaffData
            heading='Haik Divanyan'
            work='Software Engineer at SpaceX'
            school='University of California, Los Angeles'
            degree='B.S in Computer Science'
            img={haikD}
            />
            <StaffData
            heading='Gevorg Akopyan'
            work='Software Engineer Intern at Amazon'
            school='University of California, Los Angeles'
            degree='B.S in Computer Science'
            img={gevA}
            />
        </div>
        <div className='staff-group'>
            <StaffData
            heading='Anthony Degoian'
            work='Dental Student'
            school='University of California Berkeley / Herman Ostrow School of Dentistry of USC'
            degree='B.A in Armenian Studies'
            img={anthonyD}
            />
            <StaffData
            heading='Luciano Barretto'
            work='Finance'
            school='University of California, San Diego'
            degree='B.A in Economics'
            img={lucianoB}
            />
            <StaffData
            heading='Jonathan Semerjian'
            work='Esquire'
            school='University of California, Los Angeles / Dale E. Fowler School of Law'
            degree='B.A in Sociology / Juris Doctor'
            img={jonS}
            />
        </div>
        <div className='staff-group'>
          <StaffData
            heading='Shushanna Darbinian'
            work='Crisis Intervention Counseling, Psychology Research Participation Program, and Palliative Care Volunteering'
            school='University of California, Berkeley'
            degree='B.A in Psychology'
            img={shushannaD}
          />
        </div>
    </div>
  );
}

export default StaffSection;
